<template>
  <v-dialog width="640" persistent :value="true" @input="emitClose">
    <v-card class="modal-config-area">
      <v-card-title class="modal-header">
        {{ $t('config_area.header') }}
      </v-card-title>

      <v-card-text class="modal-content">
        <div class="introduction">
          <h3>{{ $t('config_area.title') }}</h3>
          <article>
            {{ $t('config_area.article') }}
          </article>
        </div>

        <div class="my-area" v-if="configData.info_area">
          <p>
            <strong>{{ $t('config_area.area_contracted') }}</strong>
            {{ haFormat(configData.info_area.total) }}
          </p>
          <p>
            <strong>{{ $t('config_area.area_remaning') }}</strong>
            {{ haFormat(configData.info_area.remaining) }}
          </p>
        </div>

        <div class="info-area">
          <font-awesome-icon icon="lightbulb" />
          <article>{{ $t('config_area.info') }}</article>
        </div>

        <div class="wrapper-config">
          <v-list dense>
            <v-list-item
              v-for="(plot, index) in configData.plots"
              :key="index"
              class="list-item"
            >
              <v-list-item-content>
                <div class="wrapper-title">
                  <h3 class="item-title">{{ plot.name }}</h3>
                  <p v-if="plot.enabled_ndvi" class="badge">NDVI</p>
                </div>
                <p class="item-subtitle">
                  {{ $t('config_area.wrapper_list.area_total') }}:
                  {{ plot.area }} ha
                </p>
              </v-list-item-content>

              <v-list-item-action>
                <!-- TODO: debito tecnico - detalhes no PRD -->

                <!-- <v-btn class="edit-btn" text @click="handleEdit(plot)">
                  {{ $t('config_area.wrapper_list.edit_area') }}
                </v-btn> -->
                <v-switch
                  class="toggle-switch"
                  v-model="plot.enabled_sowing"
                  dense
                  @change="markAsModified(plot)"
                ></v-switch>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </div>

        <div v-if="loading" class="overlay">
          <v-progress-circular indeterminate size="32" color="green" />
        </div>
      </v-card-text>

      <v-card-actions class="modal-footer">
        <v-btn @click="emitClose">{{ $t('config_area.actions.cancel') }}</v-btn>
        <v-btn class="submit" @click="submit" :disabled="isSaveDisabled">{{
          $t('config_area.actions.save')
        }}</v-btn>
      </v-card-actions>

      <!-- ndvi -->
      <modal-alert :dialog="showAlert" @close="showAlert = false" />
    </v-card>

    <plot-register
      v-if="showEditPlot"
      :plot="currentPlot"
      :plots="plotsByAccount"
      :accounts-plots-area="accountsPlotsArea"
      @refresh="reloadPlots"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import ModalAlert from './ModalAlertNdvi'
import PlotRegister from '@/pages/fazendas/ModalMaps.vue'

export default {
  name: 'ModalConfigArea',

  components: {
    ModalAlert,
    PlotRegister,
  },

  data() {
    return {
      loading: false,
      showAlert: false,
      configData: {
        info_area: null,
        plots: [],
      },
      currentPlot: null,
      plotsByAccount: [],
      accountsPlotsArea: null,
      showEditPlot: false,
    }
  },

  async mounted() {
    await this.getAllPlots()
    await this.reloadPlots()
  },

  computed: {
    ...mapGetters('farms', ['currentFarm']),
    ...mapGetters('user', ['currentUser']),
    ...mapGetters('plot', ['plots']),

    accountId() {
      return this.currentUser?.account?.id
    },

    isSaveDisabled() {
      return !this.configData.plots.some((plot) => plot.modified)
    },
  },

  methods: {
    ...mapActions('plot', ['getPlots']),

    async getAllPlots() {
      this.loading = true
      try {
        const { data } = await this.$api.talhoes.getAllByAccount(this.accountId)

        this.configData = {
          ...data,
          info_area: {
            total: Number(data.info_area.total.toFixed(2)),
            remaining: Number(data.info_area.remaining.toFixed(2)),
          },
          plots: data.plots.map((plot) => ({
            ...plot,
            area: Number(plot.area),
            enabled_sowing: plot.enabled_sowing === 1,
            initialEnabledSowing: plot.enabled_sowing === 1,
            lastEnabledSowing: plot.enabled_sowing === 1,
            modified: false,
          })),
        }
      } catch (error) {
        console.error('Erro ao buscar plots:', error)
      } finally {
        this.loading = false
      }
    },

    async getAccountsPlots() {
      try {
        const response = await this.$api.talhoes.getByAccount()
        this.accountsPlotsArea = Number(response?.data) ?? null
      } catch (error) {
        console.error('Erro ao buscar áreas por conta:', error)
      }
    },

    async reloadPlots() {
      this.loading = true
      this.showEditPlot = false
      try {
        await this.getAccountsPlots()
      } finally {
        this.loading = false
      }
    },

    async updatePlots(payload) {
      try {
        const { data } = await this.$api.talhoes.updateContractedArea(payload)

        if (data) {
          this.emitClose()
          this.$root.$emit(
            'notify',
            'success',
            this.$t('talhoes.config_area.success')
          )
        }
      } catch (error) {
        console.error('Erro ao atualizar talhões:', error)
      }
    },

    submit() {
      const payload = this.configData.plots
        .filter((plot) => plot.modified)
        .map((plot) => ({
          plot_id: plot.id,
          enable_sowing: plot.enabled_sowing ? true : false,
        }))

      if (payload.length > 0) {
        this.updatePlots(payload)
      }
    },

    // TODO: technical debt – details in the PRD
    // handleEdit(plot) {
    //   if (plot.enabled_ndvi) {
    //     this.showAlert = true;
    //   } else {
    //     this.editAreaPlot(plot.id);
    //   }
    // },

    // async editAreaPlot(plot_id) {
    //   if (!this.currentFarm?.id) {
    //     console.error('ID da fazenda não encontrado');
    //     return;
    //   }

    //   this.loading = true;

    //   const payload = {
    //     farmId: this.currentFarm?.id,
    //     params: 'enabled:desc,created_at:desc',
    //   };

    //   try {
    //     const response = await this.getPlots(payload);

    //     if (response && response.length > 0) {
    //       this.plotsByAccount = response;

    //       const selectedPlot = response.find((plot) => plot.id === plot_id);

    //       if (!selectedPlot) {
    //         console.error(`Talhão com ID ${plot_id} não encontrado.`);
    //         return;
    //       }

    //       this.currentPlot = selectedPlot;
    //     } else {
    //       console.error('Nenhum talhão encontrado.');
    //       return;
    //     }

    //     this.showEditPlot = true;
    //   } catch (error) {
    //     console.error('Erro ao abrir o modal de edição:', error);
    //   } finally {
    //     this.loading = false;
    //   }
    // },

    markAsModified(plot) {
      const isModified = plot.enabled_sowing !== plot.lastEnabledSowing

      if (isModified) {
        if (plot.enabled_sowing) {
          this.configData.info_area.remaining = Number(
            (this.configData.info_area.remaining - plot.area).toFixed(2)
          )
        } else {
          this.configData.info_area.remaining = Number(
            (this.configData.info_area.remaining + plot.area).toFixed(2)
          )
        }

        plot.lastEnabledSowing = plot.enabled_sowing
        plot.modified = plot.enabled_sowing !== plot.initialEnabledSowing
      }
    },

    haFormat(value) {
      const numValue = Number(value)
      return `${numValue.toFixed(2)} ha`
    },

    emitClose() {
      this.$emit('close')
    },
  },
}
</script>

<style scoped>
::v-deep .v-dialog {
  max-height: 90vh !important;
  height: 100% !important;
  overflow: hidden;
}

.modal-config-area {
  height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-header {
  height: 65px;
  flex-shrink: 0;
  padding: 16px 24px !important;
  border-bottom: 1px solid #ccc;
}

.modal-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 32px 24px 10px 24px !important;
  overflow: hidden;
}

.introduction {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.introduction h3 {
  color: #1a2b46;
  font-size: 18px;
}

.introduction article,
.info-area p,
.wrapper-config .list-item .item-subtitle {
  font-size: 16px;
  font-weight: 400;
  color: #5b6459;
  margin: 0;
}

.my-area {
  width: 100%;
  padding: 12px 16px;
  border: 1px solid #e6e9e6;
  background: #f8f9f8;
  border-radius: 4px;
  margin-top: 16px;
}

.my-area p,
.info-area p {
  margin: 0;
  font-size: 14px;
}

.info-area {
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 12px;
  margin-top: 16px;
  background: #e8f0ff;
  border: 1px solid #92bcff;
  border-radius: 4px;
}

.info-area svg {
  color: #4a76bc;
  font-size: 20px;
}

.wrapper-config {
  margin-top: 16px;
  flex: 1;
  height: 100%;
  overflow: hidden scroll;
}

.wrapper-config .v-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 !important;
}

.list-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  border-bottom: 1px solid #e6e9e6;
}
.list-item:last-child {
  border-bottom: none;
}

.list-item .v-list-item__content,
.wrapper-title,
.v-list-item__action {
  display: flex;
  align-items: center;
}

.v-list-item__content {
  padding: 0;
  gap: 5px;
}

.wrapper-title {
  gap: 16px;
}

.item-title {
  font-size: 16px;
  font-weight: 600;
  color: #1a2b46;
  margin: 0;
}

.badge {
  background: #e6e6e6;
  padding: 6px 8px;
  border-radius: 16px;
  color: #5b6459;
  font-size: 12px;
  font-weight: 600;
  margin: 0;
}

.v-list-item__action {
  flex-direction: row;
  gap: 12px;
}

.v-list-item__action .edit-btn {
  font-weight: 600;
  font-size: 14px;
  color: #39af49;
  text-transform: capitalize;
}

::v-deep
  .modal-content
  .wrapper-config
  .toggle-switch
  .v-input--switch__track.theme--light.primary--text {
  background-color: #39af49 !important;
}

::v-deep
  .modal-content
  .wrapper-config
  .toggle-switch
  .v-input--switch__thumb.theme--light.primary--text {
  background-color: #fff !important;
}

.modal-footer {
  width: 100%;
  height: 73px;
  flex-shrink: 0;
  background-color: #fff;
  padding: 16px 24px !important;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-top: 1px solid #e6e9e6;
}
.modal-footer button {
  box-shadow: none;
  height: 40px !important;

  border: 1px solid #e6e9e6;
  padding: 16px !important;

  text-transform: capitalize;
  font-size: 14px;
  color: #1a2b46;
  font-weight: 500;
}

.modal-footer button.submit {
  background: #39af49;
  color: #fff;
}

.overlay {
  width: 100%;
  height: calc(100% - 63px);
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1030;
  background: rgb(255 255 255 / 90%);
}
</style>
