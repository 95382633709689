<template>
  <div v-if="!isMobile" class="harvest-register">
    <df-loading v-show="loading" />
    <div
      v-show="!loading"
      class="harvest-register__plots-list"
      v-for="(plot, index) in $v.sowingsByPlots.$each.$iter"
      :key="index"
    >
      <v-row no-gutters class="title-header text-uppercase" v-show="!loading">
        <v-col cols="5" class="text-header">
          {{ $t('menu.semeaduras') }} - {{ plot.name.$model }}
        </v-col>
        <template v-if="index == 0">
          <v-col cols="2" class="text-start pl-4">
            {{ $t('season_report.bags_per_hectare') }}
          </v-col>
          <v-col cols="3" class="text-start pl-4">
            {{ $t('season_report.when_harvest') }}?
          </v-col>
          <v-col cols="1" class="text-center pl-0">
            {{ $t('season_report.desiccation') }}?
          </v-col>
          <v-col cols="1" class="text-center pl-0">
            {{ $t('harvest.lost') }}?
          </v-col>
        </template>
      </v-row>

      <div
        class="harvest-register__sowings-list mb-n1"
        v-for="sowing in plot.sowings.$each.$iter"
        :key="sowing.sowingId.$model"
      >
        <v-row class="harvest-register__form">
          <v-col cols="2" class="text-start pb-0 pt-0">
            <div class="icon-image">
              <v-img
                max-width="18px"
                :alt="cropIcon().alt"
                :src="cropIcon().src"
              />
              <span class="pl-2">{{ sowing.varietyName.$model }}</span>
            </div>
          </v-col>
          <v-col cols="1" class="pb-0 px-0 pt-0">
            <df-tooltip bottom :text="$t('planting.sown_area')">
              <div>
                <font-awesome-icon
                  class="mr-3"
                  color="#788476"
                  icon="vector-square"
                />
                <span>{{ sownArea(sowing.area.$model) }}</span>
              </div>
            </df-tooltip>
          </v-col>
          <v-col cols="2" class="pb-0 pr-0 pt-0">
            <df-tooltip bottom :text="$t('planting.sowing_date')">
              <div>
                <font-awesome-icon
                  class="mr-3"
                  color="#788476"
                  icon="seedling"
                />
                <span>{{ sowing.date.$model.formatDate() }}</span>
              </div>
            </df-tooltip>
          </v-col>
          <v-col cols="2" class="text-end pb-0 pt-0">
            <converter-field
              custom-class="harvest-register__field"
              v-model="sowing.sacs.$model"
              background-color="white"
              dense
              outlined
              :errors="getMessage(sowing)"
              @blur="validate(sowing)"
              :key="$currentLanguage()"
              unit-measure="performance"
            />
          </v-col>
          <v-col cols="3" class="text-end pb-0 pr-5 pt-0">
            <df-date-picker
              :label="$t('season_report.harvest_date')"
              v-model="sowing.harvestDate.$model"
              :error-messages="getMessageDate(sowing)"
              @change="validate(sowing)"
              color="#39af49"
              solo
              dense
              outlined
            />
          </v-col>
          <v-col cols="1" class="text-end pb-0 pt-0">
            <v-switch
              class="harvest-register____switch-field mt-0"
              v-model="sowing.desiccation.$model"
              inset
              :label="sowing.desiccation.$model ? $t('yes') : $t('no')"
              dense
            />
          </v-col>
          <v-col cols="1" class="text-end pb-0 pt-0">
            <v-switch
              class="harvest-register____switch-field mt-0"
              v-model="sowing.lost.$model"
              @change="validate(sowing)"
              inset
              :label="sowing.lost.$model ? $t('yes') : $t('no')"
              dense
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
  <div v-else class="harvest-register">
    <df-loading v-show="loading" />
    <div
      v-show="!loading"
      class="harvest-register__plots-list"
      v-for="(plot, index) in $v.sowingsByPlots.$each.$iter"
      :key="index"
    >
      <div
        class="harvest-register__sowings-list mb-n3"
        v-for="sowing in plot.sowings.$each.$iter"
        :key="sowing.sowingId.$model"
      >
        <v-row no-gutters class="harvest-register__form">
          <v-col cols="1">
            <v-img
              max-width="18px"
              :alt="cropIcon().alt"
              :src="cropIcon().src"
            />
          </v-col>
          <v-col cols="4" class="text-start pb-0 pt-0">
            {{ sowing.varietyName.$model }}
          </v-col>
          <v-col cols="3" class="harvest-register__form2 text-start pb-2">
            <df-tooltip bottom :text="$t('planting.sown_area')">
              <div>
                <font-awesome-icon
                  class="mr-2"
                  color="#788476"
                  icon="vector-square"
                />
                <span>{{ sownArea(sowing.area.$model) }}</span>
              </div>
            </df-tooltip>
          </v-col>
          <v-col cols="4" class="harvest-register__form2">
            <df-tooltip bottom :text="$t('planting.sowing_date')">
              <div>
                <font-awesome-icon
                  class="mr-2 ml-n1"
                  color="#788476"
                  icon="seedling"
                />
                <span>{{ sowing.date.$model.formatDate() }}</span>
              </div>
            </df-tooltip>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="4" class="text-end pb-0 pt-0">
            <converter-field
              :label="$unitMeasures['performance'][$currentLanguage()]"
              custom-class="harvest-register__field"
              v-model="sowing.sacs.$model"
              background-color="white"
              dense
              outlined
              :error-messages="getMessage(sowing.sowingId.$model)"
              @blur="validate(sowing)"
              :key="$currentLanguage()"
              unit-measure="performance"
            />
          </v-col>

          <v-col cols="1">
            <v-spacer />
          </v-col>

          <v-col cols="7" class="text-end pb-0 pr-5 pt-0">
            <df-date-picker
              :label="$t('season_report.harvest_date')"
              v-model="sowing.harvestDate.$model"
              :error-messages="getMessageDate(sowing)"
              @change="validate(sowing)"
              solo
              dense
            />
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="title-header text-uppercase d-flex align-center"
        >
          <v-col cols="8">{{ $t('season_report.desiccation') }}? </v-col>

          <v-col cols="4">
            <v-switch
              v-model="sowing.desiccation.$model"
              inset
              :label="sowing.desiccation.$model ? $t('yes') : $t('no')"
              dense
              hide-details
            />
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="title-header text-uppercase d-flex align-center"
        >
          <v-col cols="8" class="pb-4">{{ $t('harvest.lost') }}? </v-col>
          <v-col cols="4">
            <v-switch
              v-model="sowing.lost.$model"
              @change="validate(sowing)"
              inset
              :label="sowing.lost.$model ? $t('yes') : $t('no')"
              dense
            />
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minValue } from 'vuelidate/lib/validators'
import DfDatePicker from '@/lib/Form/DatePicker'
import DfLoading from '@/lib/components/Loader'
import DfTooltip from '@/lib/Tooltip'
import LayoutMixin from '@/components/LayoutMixin'
import { mapGetters } from 'vuex'
import { convertToTargetUnit } from '@/components/Form/converter'
import ConverterField from '@/components/Form/ConverterField'

export default {
  name: 'SowingsToHarvestForm',

  mixins: [LayoutMixin, validationMixin],

  data() {
    return {
      loading: false,
      sowingsByPlots: [],
      errors: [],
    }
  },

  components: {
    DfDatePicker,
    DfLoading,
    DfTooltip,
    ConverterField,
  },

  watch: {
    sowingsByPlots: {
      handler: function (val) {
        this.getFilledHarvests(val)
      },
      deep: true,
    },
  },

  async created() {
    await this.loadPlantingsByPlots(this.currentFarmId)
  },

  validations: {
    sowingsByPlots: {
      $each: {
        name: {},
        sowings: {
          $each: {
            sowingId: {},
            varietyName: {},
            area: {},
            date: {},
            harvestId: {},
            desiccation: {},
            lost: {},
            harvestDateToggle: {},
            sacs: {
              required,
              minValue: minValue(0),
            },
            harvestDate: {
              required,
            },
          },
        },
      },
    },
  },

  computed: {
    ...mapGetters('farms', ['currentFarmId']),
    ...mapGetters('crops', ['isSoybean']),
  },

  methods: {
    async loadPlantingsByPlots(farmId) {
      this.loading = true
      const { data } =
        await this.$api.semeaduras.colheita.sowingsReadyToHarvest(farmId)
      this.sowingsByPlots = this.formatList(data)

      this.countSowings(this.sowingsByPlots)

      this.loading = false
    },

    cropIcon() {
      const cropImageName = this.isSoybean ? 'soybean' : 'wheat'
      return {
        alt: cropImageName,
        src: require(`@/assets/icons/ic-${cropImageName}.svg`),
      }
    },

    getFilledHarvests(val) {
      this.harvests = val.flatMap((plot) =>
        plot.sowings.map((sowing) => ({
          planting_id: sowing.sowingId,
          harvested_at: sowing.harvestDate,
          productivity: sowing.sacs,
          desiccation: sowing.desiccation,
          lost: sowing.lost,
        }))
      )

      this.filterHarvests()
    },

    filterHarvests() {
      const filledHarvests = this.harvests.filter(
        (harvest) =>
          harvest.harvested_at || harvest.productivity || harvest.lost
      )

      this.$emit('form-updated', filledHarvests)
    },

    getMessage(sowing) {
      const errorInDate = this.getMessageDate(sowing)

      if (
        sowing?.sacs?.$model === null &&
        errorInDate === '' &&
        sowing?.harvestDate?.$model === ''
      ) {
        return []
      } else if (sowing?.sacs?.$model === null && errorInDate !== '') {
        return [this.$t('required')]
      } else {
        const notFilled = !sowing?.sacs?.$model && !sowing?.lost?.$model
        return notFilled ? [this.$t('required')] : []
      }
    },

    getMessageDate(sowing) {
      const error = this.errors.find(
        (x) => x.sowingId === sowing.sowingId.$model
      )

      return sowing?.harvestDate?.$model === '' &&
        error?.harvestDate &&
        !error?.lost
        ? this.$t('required')
        : ''
    },

    validate(sowing) {
      if (sowing.harvestDate.$invalid || sowing.sacs.$invalid) {
        this.addError(sowing.sowingId.$model, {
          sowingId: sowing.sowingId.$model,
          sacs: sowing.sacs.$invalid,
          harvestDate: sowing.harvestDate.$invalid,
          lost: sowing.lost.$model,
        })
      }
    },

    addError(sowingId, data) {
      const index = this.errors.findIndex((x) => x.sowingId == sowingId)
      if (index == -1) {
        this.errors.push(data)
      } else {
        this.$set(this.errors, index, data)
      }
    },

    formatList(result) {
      return result.map(({ plot_name, sowings: plot_sowings }) => {
        const sowings = plot_sowings.map(
          ({ sowing_area, sowing_date, sowing_id, variety_name }) => {
            return {
              sowingId: sowing_id,
              varietyName: variety_name,
              area: sowing_area,
              date: sowing_date,
              sacs: null,
              harvestId: null,
              harvestDate: '',
              desiccation: false,
              lost: false,
              harvestDateToggle: false,
            }
          }
        )
        return {
          name: plot_name,
          sowings,
        }
      })
    },

    countSowings(sowingsByPlots) {
      let count = 0
      sowingsByPlots.forEach((plot) => {
        count += plot.sowings.length
      })

      this.$emit('sowings-count', count)
    },
    // accessed by refs
    // eslint-disable-next-line vue/no-unused-properties
    resetValidators() {
      this.errors = []
    },

    sownArea(area) {
      return convertToTargetUnit(area, 'area')
    },
  },
}
</script>

<style scoped>
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  box-shadow: none !important;
}

::v-deep
  .v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
  > .v-input__control
  > .v-input__slot {
  border-radius: 8px;
}

::v-deep .v-input__append-inner {
  margin-top: 0px !important;
}

::v-deep.theme--light.v-image {
  width: 18px;
}

.harvest-register {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 500px;
  padding: 21px 42px;
  background: #f5f5f5 !important;
}

.harvest-register__header {
  font-size: 12px;
  font-weight: 700;
  color: #aab2a9;
}

.harvest-register__header .col:first-child {
  color: #071505;
}

.harvest-register__form {
  font-size: 18px;
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  align-items: baseline;
}

.harvest-register__form .col:first-child {
  font-weight: 700;
  color: #071505;
}

.harvest-register__switch-field .v-input__slot {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: end !important;
}

.harvest-register__switch-field
  .v-input__slot
  .v-input--selection-controls__input {
  margin-right: 0px !important;
}

.harvest-register__switch-field .v-input__slot .v-label {
  color: #788476;
  font-weight: 700;
  -webkit-box-flex: 0 !important;
  -ms-flex: none !important;
  flex: none !important;
}

.harvest-register .text-header {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #1a2b46;
}

.harvest-register .title-header {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #aab2a9;
}

.harvest-register__form2 {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  color: #5b6459;
}

.icon-image {
  display: inline-flex;
  align-items: center;
}

@media (max-width: 768px) {
  ::v-deep .v-input--selection-controls {
    margin-top: 0px;
    padding-top: 0px;
  }

  ::v-deep .v-input__slot {
    min-height: 30px !important;
  }

  ::v-deep .v-text-field--outlined.v-input--dense .v-label {
    top: 7px;
  }
  .harvest-register {
    padding: 21px 16px;
  }

  .harvest-register .title-header {
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
  }
}
</style>
